<template>
    <div class="select-container">
        <div class="container">
            <div class="select-area">
                <el-select v-model="$store.state.assessObj"
                           value-key="assess_num"
                           placeholder="请选择轮次"
                           @change="onChangeAssessNum"
                >
                    <el-option
                            v-for="(item, index) in confAssessNum"
                            :key="index"
                            :label="item.name"
                            :value="item"
                    ></el-option>
                </el-select>
                <el-select v-model="$store.state.subjectObj"
                           value-key="subject_uuid"
                           placeholder="请选择科目"
                           @change="onChangeSubject"
                >
                    <el-option
                            v-for="(item, index) in confSubject"
                            :key="index"
                            :label="item.subject_name"
                            :value="item"
                    ></el-option>
                </el-select>
            </div>
            <div class="btn-area">
                <el-button @click="selectHandle(1)">阅卷</el-button>
                <!--<el-button @click="selectHandle(3)" v-if="$store.state.assess_num==1">二次阅卷</el-button>
                <el-button @click="selectHandle(2)">仲裁</el-button>
                <el-button @click="selectHandle(4)">评阅</el-button>-->
            </div>
        </div>
        <div class="logout" @click="logOut">退出</div>
    </div>
</template>

<script>
    import {mapMutations} from "vuex";
    import {loginOut} from "../../api/login";
    import {getYjConf, getYjConfEnd, getYjConf2, getYjConfAll} from "../../api/select";

    export default {
        name: "Select",
        data() {
            return {
                confAssessNum: [],
                confSubject: [],
                assessObj: {},
                subjectObj: {},
            };
        },
        created() {
            this.getYjConfAllHandle();
        },
        methods: {
            ...mapMutations([
                "updateTeacherType",
                "updateConfAssessNum",
                "updateConfSubject",
                "updateAssessNum",
                "updateSubjectUuid",
                "loginOut"
            ]),
            logOut() {
                let _this = this;
                _this.$store.commit('updateLoginOutDialog', true);
                // _this.$confirm('确认退出登录?', '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // }).then(async () => {
                //     await loginOut();
                //     setTimeout(() => {
                //         _this.loginOut()
                //     }, 100)
                // }).catch(() => {
                //     _this.$message({
                //         type: 'info',
                //         message: '已取消操作'
                //     });
                // });
            },
            onChangeAssessNum(val) {
                let _this = this;
                _this.updateAssessNum(val);
            },
            onChangeSubject(val) {
                let _this = this;
                _this.updateSubjectUuid(val);
            },
            selectHandle(type) {
                let _this = this;
                if (!_this.$store.state.assessObj || Object.keys(_this.$store.state.assessObj).length == 0) {
                    return _this.$message.info('请选择轮次');
                }
                if (!_this.$store.state.subjectObj || Object.keys(_this.$store.state.subjectObj).length == 0) {
                    return _this.$message.info('请选择科目');
                }
                _this.updateTeacherType(type);
                if (type == 1) {
                    _this.requestHandle(getYjConf);
                } else if (type == 2) {
                    _this.requestHandle(getYjConfEnd);
                } else if (type == 3) {
                    _this.requestHandle(getYjConf2);
                }else if(type == 4){
                    _this.requestHandle(getYjConfAll);
                }

            },
            requestHandle(api) {
                let _this = this;
                api({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return _this.$message.error(res.data.message);
                        _this.updateConfAssessNum(res.data.data.conf_assess_num);
                        _this.updateConfSubject(res.data.data.conf_subject);
                        _this.$store.dispatch('GenerateRoutes').then(response => {
                            console.log(response)
                            if (response.code != 200) return _this.$message.error(response.message);
                            _this.$router.replace('/mark/giveMark');
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 获取当前登录的公共配置 轮次与科目等
            getYjConfAllHandle() {
                let _this = this;
                getYjConfAll({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return _this.$message.error(res.data.message);
                        let _this = this;
                        _this.confAssessNum = res.data.data.conf_assess_num;
                        _this.confSubject = res.data.data.conf_subject;
                        if (_this.confAssessNum.length == 0) {
                            let _this = this;
                            _this.onChangeAssessNum({});
                        }else{
                            _this.onChangeAssessNum(res.data.data.conf_assess_num[0]);
                        }
                        if (_this.confSubject.length == 0) {
                            _this.onChangeSubject({});
                        }else{
                            _this.onChangeSubject(res.data.data.conf_subject[0]);
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
    };
</script>

<style scoped lang="scss">
    @import "../../assets/css/common";

    .select-container {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 1024px;
        background-color: #f2edf4;

        .logout {
            position: absolute;
            top: 50px;
            right: 50px;
            font-size: 20px;
            cursor: pointer;

            &:hover {
                color: #5c307d;
            }
        }

        .container {
            width: 560px !important;
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, 0);
            right: 0;
            z-index: 1;

            .select-area {
                display: flex;
                flex-flow: column;
                justify-content: center;
                margin-bottom: 30px;

                ::v-deep .el-select {
                    width: 100%;
                    margin-bottom: 10px;

                    .el-input.is-focus {
                        .el-input__inner {
                            border-color: #5c307d;
                        }
                    }

                    .el-input__inner {
                        height: 60px;
                        line-height: 60px;

                        &:hover,
                        &:focus {
                            border-color: #5c307d;
                        }
                    }
                }
            }

            .btn-area {
                display: flex;
                flex-flow: row;
                justify-content: center;

                .el-button {
                    /*width: 180px;*/
                    flex: 1;
                    height: 60px;
                    font-size: 20px;
                    color: #5c307d;
                    border-color: #5c307d;
                    background-color: #ffffff;

                    &:hover{
                        color: #ffffff;
                        border-color: #5c307d;
                        background-color: #5c307d;
                    }
                    /*&:nth-child(odd) {*/
                    /*    color: #5c307d;*/
                    /*    border-color: #5c307d;*/
                    /*    background-color: #ffffff;*/
                    /*}*/

                    /*&:nth-child(even) {*/
                    /*    color: #ffffff;*/
                    /*    border-color: #5c307d;*/
                    /*    background-color: #5c307d;*/
                    /*}*/
                }
            }
        }
    }
</style>
