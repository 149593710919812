import router from "../router";

export default {
    // 更新上一页信息
    setPrePage(state, path) {
        state.previousPage = path;
    },
    // 保存数据
    setData(state, city) {
        state.data = city;
    },

    login(state) {
        state.isLogin = "1";
        localStorage.setItem("isLogin", "1");
    },
    loginOut(state) {
        state.isLogin = "0";
        window.localStorage.clear();
        window.sessionStorage.clear();
        state.assessObj = {};
        state.assess_num = null;
        state.subjectObj = {};
        state.subject_uuid = null;
        state.conf_assess_num = {};
        state.conf_subject = [];
        state.examInfo = {};
        state.menuList = [];
        state.levelScoreRoteList = [];
        state.otherScoreRoteList = [];
        state.scoreRoteList = [];
        router.replace('/login')
    },
    updateLoginOutDialog(state, data) {
        state.loginOutDialog = data;
    },
    updateExamInfo(state, data) {
        state.examInfo = data;
    },
    updateFilePath(state, data) {
        state.filePath = data;
    },
    updateTeacherType(state, data) {
        state.teacherType = data;
    },
    updateScoreRoteList(state, list) {
        state.scoreRoteList = list;
    },
    updateMenuList(state, list) {
        state.menuList = list;
    },
    updateConfAssessNum(state, list) {
        state.conf_assess_num = list;
    },
    updateConfSubject(state, list) {
        state.conf_subject = list;
    },
    updateAssessNum(state, data) {
        state.assessObj = data;
        state.assess_num = data.assess_num ? data.assess_num : null;
    },
    updateSubjectUuid(state, data) {
        state.subjectObj = data;
        state.subject_uuid = data.subject_uuid ? data.subject_uuid : null;
    },
    updateScoreSum(state, data) {
        state.score_sum = data;
    },
    updateScoreAllSum(state, data) {
        state.score_all_sum = data;
    },
    updateScoreSumCost(state, data) {
        state.score_sum_cost = data;
    },
    updateOtherScoreRoteList(state, list) {
        let arr = [];
        if (list.length > 0) {
            let groups = {};
            list.forEach(num => {
                let groupKey = Math.floor(num.score / 10) * 10 + "-" + (Math.floor(num.score / 10) * 10 + 9);
                if (!groups[groupKey]) {
                    groups[groupKey] = [];
                }
                groups[groupKey].push(num);
            });
            for (let key in groups) {
                groups[key] = groups[key].sort((a, b) => {
                    return a.score - b.score
                });
                arr.push(groups[key])
            }
        }
        state.otherScoreRoteList = arr;
    },
    updateLevelScoreRoteList(state, list) {
        state.levelScoreRoteList = list;
    },
};
