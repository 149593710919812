import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Index.vue'
import Select from '../views/login/select.vue'
// import Abnormal from '../views/abnormal/index.vue'
// import mark from './index/mark'
// import score from './index/score'
import store from "../store";

Vue.use(VueRouter)
export const basePath = [
    {
        path: '/mark',
        name: 'mark',
        meta: {
            title: '作品评分'
        },
        hidden: false,
        examTypeHidden: 2,// 1-2 exam_type!=2显示；2-3 exam_type==2显示
        component: () => import("../views/Index"),
        children: []
    },
    {
        path: '/score',
        name: 'score',
        meta: {
            title: '已评作品'
        },
        hidden: false,
        examTypeHidden: 1,
        component: () => import("../views/Index"),
        children: [
            {
                path: 'giveMark',
                name: 'reviewed',
                meta: {title: '已评阅'},
                hidden: true,
                showNum: false,
                component: () => import('../views/score/reviewed'),
            },
            {
                path: 'history',
                name: 'history',
                meta: {title: '历史评阅'},
                hidden: false,
                showNum: false,
                component: () => import('../views/score/history'),
            },
            {
                path: 'contrast',
                name: 'contrast',
                meta: {title: '作品对比'},
                hidden: false,
                showNum: false,
                component: () => import('../views/score/contrast'),
            },
        ]
    },
    {
        path: '/abnormal',
        name: 'abnormal',
        meta: {
            title: '异常系统'
        },
        hidden: false,
        examTypeHidden: 1,
        component: () => import("../views/Index"),
        children: [
            {
                path: 'list',
                name: 'abnormal',
                meta: {title: '异常列表'},
                hidden: false,
                showNum: false,
                component: () => import('../views/abnormal/index'),
            },
        ]
    },
    {
        path: '/anomaly',
        name: 'anomaly',
        meta: {
            title: '异常系统'
        },
        hidden: false,
        examTypeHidden: 3,
        component: () => import("../views/Index"),
        children: [
            {
                path: 'list',
                name: 'anomaly',
                meta: {title: '异常列表'},
                hidden: false,
                showNum: false,
                component: () => import('../views/anomaly/index'),
            },
            {
                path: 'giveMark',
                name: 'giveMark',
                meta: {title: '异常详情'},
                hidden: true,
                showNum: false,
                component: () => import('../views/anomaly/giveMark'),
            },
        ]
    },
    {
        path: '/normal',
        name: 'normal',
        meta: {
            title: '无异常系统'
        },
        hidden: false,
        examTypeHidden: 3,
        component: () => import("../views/Index"),
        children: [
            {
                path: 'list',
                name: 'normal',
                meta: {title: '无异常列表'},
                hidden: false,
                showNum: false,
                component: () => import('../views/normal/index'),
            },
            {
                path: 'giveMark',
                name: 'giveMark',
                meta: {title: '无异常详情'},
                hidden: true,
                showNum: false,
                component: () => import('../views/normal/giveMark'),
            },
        ]
    },
    {
        path: '/wait',
        name: 'wait',
        meta: {
            title: '分档系统'
        },
        hidden: false,
        examTypeHidden: 1,
        component: () => import("../views/Index"),
        children: [
            {
                path: 'list',
                name: 'wait',
                meta: {title: '分档列表'},
                hidden: false,
                showNum: false,
                component: () => import('../views/wait/index'),
            },
            {
                path: 'giveMark',
                name: 'giveMark',
                meta: {title: '分档评分'},
                hidden: true,
                showNum: false,
                component: () => import('../views/wait/giveMark'),
            },
        ]
    },
    {
        path: '/scale',
        name: 'scale',
        meta: {
            title: '统计系统'
        },
        hidden: false,
        examTypeHidden: 1,
        component: () => import("../views/Index"),
        children: [
            {
                path: 'score',
                name: 'score',
                meta: {title: '成绩比例'},
                hidden: false,
                showNum: false,
                component: () => import('../views/scale/index'),
            },
        ]
    }
]
export const routes = [
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登录'
        },
        hidden: true,
        component: Login
    },
    {
        path: '/select',
        name: 'Select',
        meta: {
            title: '选择'
        },
        hidden: true,
        component: Select
    },
    {
        path: '/',
        name: 'Index',
        hidden: true,
        meta: {
            title: '作品评分'
        },
        component: () => import("../views/Index"),
        redirect: '/mark/giveMark', //页面默认加载的路由
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location) {
    return originalReplace.call(this, location).catch(err => err)
}
store.dispatch('GenerateRoutes').then(response => {
    return false;
}).catch(error => {
    return false;
})
router.beforeEach((to, from, next) => {
    if (to.query && to.query.file_path) {
        store.commit('updateFilePath', to.query.file_path + "/");
    }
    document.title = to.meta.title;
    let token = window.localStorage.getItem('index-token');
    if (token === null || token === "" || !token) {
        if (to.path == '/login') {
            next()
        } else {
            next('/login')
        }
    } else {
        next()
    }
    store.fromPath = from.path;
})

//   运用vue-router的错误处理函数 onError 捕获错误(当捕获到了Loading chunk {n} failed的错误时，重新渲染目标页面。)
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，
    // 而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    if (isChunkLoadFailed) {
        location.reload();
    }
})
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
});

export default router
