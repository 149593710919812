<template>
    <div style="
      min-width: 2046px;
      height: 100%;
      min-height: 1024px;
      background-color: #f2edf4;
    "
    >
        <div class="container">
            <div id="login_form">
                <h3 style="
            color: #5c307d;
            font-size: 28px;
            text-align: center;
            margin-bottom: 30px;
          "
                >
                    清华艺术类阅卷系统
                </h3>
            </div>
            <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef">
                <el-form-item
                        prop="username"
                        class="f-f1 item-ifo"
                        style="position: relative"
                >
                    <el-input
                            class="name"
                            v-model="loginForm.username"
                            placeholder="请输入账号"
                            maxlength="20"
                            prefix-icon="el-icon-user-solid"
                            auto-complete="new-name"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        prop="password"
                        class="f-f1 item-ifo"
                        style="position: relative; padding: 0"
                >
                    <el-input
                            class="password"
                            v-model="loginForm.password"
                            type="password"
                            placeholder="请输入密码"
                            maxlength="20"
                            id="realPwd"
                            style="width: 450px"
                            show-password
                            prefix-icon="el-icon-question"
                            auto-complete="new-password"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                        prop="exam_uuid"
                        class="f-f1 item-ifo"
                        style="position: relative"
                >
                    <el-select v-model="loginForm.exam_uuid"
                               placeholder="请选择阅卷轮次"
                    >
                        <el-option
                                v-for="(item, index) in examList"
                                :key="index"
                                :label="item.exam_name"
                                :value="item.exam_uuid"
                                v-if="item.is_hide==1"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                            type="primary"
                            class="login"
                            style="width: 450px"
                            @click="submitForm()"
                    >登录
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>


<script>
    import {login, getExam} from "../../api/login.js";
    import {getYjConf, getYjConfAll} from "../../api/select";
    import {mapMutations} from "vuex";

    export default {
        name: "Login",
        // mixins: [authMixin],
        data() {
            return {
                loginForm: {
                    username: "",
                    password: "",
                    exam_uuid: "",
                },
                // 表单验证规则对象
                loginFormRules: {
                    // 对用户名进行校验
                    username: [
                        {required: true, message: "请输入账号", trigger: "blur"},
                        {
                            validator: function (rule, value, callback) {
                                if (/\s/g.test(value) === true) {
                                    callback(new Error("账号不能有空格"));
                                } else {
                                    callback();
                                }
                            },
                            trigger: "blur",
                        },
                    ],
                    // 对密码进行校验
                    password: [
                        {required: true, message: "请输入密码", trigger: "blur"},
                        {min: 5, max: 15, message: "请输入正确的密码", trigger: "blur"},
                    ],
                    // 对考试进行校验
                    exam_uuid: [
                        {required: true, message: "请选择阅卷轮次", trigger: "change"},
                    ],
                },
                examList: []
            };
        },
        created() {
            this.getExamList();
        },
        methods: {
            ...mapMutations([
                "updateTeacherType",
                "updateConfAssessNum",
                "updateConfSubject",
                "updateAssessNum",
                "updateSubjectUuid",
            ]),
            // 获取考试列表
            getExamList() {
                getExam({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return _this.$message.error(res.data.message);
                        this.examList = res.data.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 表单验证
            submitForm() {
                this.$refs.loginFormRef.validate(async (valid) => {
                    // 验证用户名和准考证号是否符合规定
                    if (!valid) return;
                    // 发送请求的操作
                    let {data} = await login(this.loginForm);
                    // console.log(data, "data");
                    // 根据返回的状态码做想关的判断
                    if (data.code != 200) return this.$message.error(data.message);
                    this.$message.success("登录成功！");
                    // token存储
                    window.localStorage.setItem("index-token", data.data.token);
                    // window.localStorage.setItem("exam_uuid", data.data.exam_uuid);
                    // window.localStorage.setItem("exam_uuid", '5YGCNP9S7ZTOAJ8UVVS2');
                    window.localStorage.setItem("user-info", JSON.stringify(data.data.info))
                    this.$store.commit('updateExamInfo', data.data.exam);
                    // 重置表单
                    this.$refs.loginFormRef.resetFields();
                    // await this.$router.replace('/select');
                    this.getYjConfAllHandle()
                });
            },
            // 获取当前登录的公共配置 轮次与科目等
            getYjConfAllHandle() {
                let _this = this;
                getYjConfAll({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return _this.$message.error(res.data.message);
                        _this.confAssessNum = res.data.data.conf_assess_num;
                        _this.confSubject = res.data.data.conf_subject;
                        if (_this.confAssessNum.length == 0) {
                            _this.updateAssessNum({});
                        } else {
                            _this.updateAssessNum(res.data.data.conf_assess_num[0]);
                        }
                        if (_this.confSubject.length == 0) {
                            _this.updateSubjectUuid({});
                        } else {
                            _this.updateSubjectUuid(res.data.data.conf_subject[0]);
                        }
                        this.selectHandle();
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            selectHandle() {
                let _this = this;
                _this.updateTeacherType(1);
                getYjConf({})
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) return _this.$message.error(res.data.message);
                        _this.updateConfAssessNum(res.data.data.conf_assess_num);
                        _this.updateConfSubject(res.data.data.conf_subject);
                        _this.$store.dispatch('GenerateRoutes').then(response => {
                            console.log(response)
                            if (response.code != 200) return _this.$message.error(response.message);
                            _this.$router.replace('/mark/giveMark');
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../assets/css/common";

    .container {
        width: 560px;
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, 0);
        right: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
    }

    .el-form-item {
        margin-bottom: 20px;
    }

    .name ::v-deep,
    .password ::v-deep {
        &.el-input__prefix {
            left: 0;
            font-size: 20px;

            .el-input__icon::before {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &.el-input__inner {
            width: 100%;
            height: 100%;
            padding-left: 40px;
            position: absolute;
            left: 0;
            border: 0;
        }
    }

    ::v-deep .el-select {
        width: 100%;
        margin-bottom: 10px;

        .el-input__inner {
            height: 48px;
            line-height: 48px;
            border: 0;
        }
    }
</style>
