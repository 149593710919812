import {getYjInfo, getYjInfoEnd, getYjInfo2, getYjInfoT} from "../api/select";
import {basePath} from "../router";
import store from "./index";
import router from "../router";


export default {
    GenerateRoutes({commit}) {
        return new Promise(resolve => {
            if (store.state.teacherType == 1) {
                // 向后端请求路由数据-阅卷
                getYjInfo({})
                    .then(res => {
                        if (res.data.code != 200) return resolve(res.data);
                        let scoreList = res.data.data.scores;
                        let pathList = scoreList.map((item, index) => {
                            let basePath = {
                                path: 'scoreRote/' + item.id,
                                name: 'score' + item.id,
                                meta: {
                                    title: item.score + '分',
                                    cost: item.cost,
                                    my_score_num: item.my_score_num,
                                    parent_id: item.parent_id,
                                    round_num: item.round_num,
                                    score_level: item.score_level,
                                },
                                hidden: false,
                                showNum: true,
                                component: () => import('../views/score/scoreRote'),
                            }
                            return basePath;
                        })
                        let routeList = basePath.filter(item => {
                            if (!item.hidden) {
                                if (item.name == 'mark') {
                                    item['children'][0] = {
                                        path: 'giveMark',
                                        name: 'giveMark',
                                        meta: {
                                            score_sum: res.data.data.score_sum,
                                            score_sum_cost: res.data.data.score_sum_cost,
                                            sum: res.data.data.sum,
                                            title: '作品评分'
                                        },
                                        hidden: false,
                                        showNum: true,
                                        component: () => import('../views/mark/giveMark'),
                                    }
                                }
                                if (item.name == 'score') {
                                    item.children = [item.children[0], item.children[1], item.children[2], ...pathList]
                                }
                                return item
                            }
                        })
                        commit('updateMenuList', routeList);
                        commit('updateScoreRoteList', scoreList);
                        commit('updateScoreSum', res.data.data.score_sum);
                        commit('updateScoreAllSum', res.data.data.sum);
                        commit('updateScoreSumCost', parseFloat(res.data.data.score_sum_cost));
                        router.addRoutes(routeList) // 动态添加可访问路由表
                        resolve(res.data)
                    })
                    .catch(err => {
                        resolve(err)
                    })
            } else if (store.state.teacherType == 2) {
                // 向后端请求路由数据-仲裁
                getYjInfoEnd({})
                    .then(res => {
                        if (res.data.code != 200) return resolve(res.data);
                        let scoreList = res.data.data.scores;
                        let routeList = basePath.filter(item => {
                            if (!item.hidden) {
                                if (item.name == 'mark') {
                                    item['children'][0] = {
                                        path: 'giveMark',
                                        name: 'giveMark',
                                        meta: {
                                            score_sum: res.data.data.score_sum,
                                            score_sum_cost: res.data.data.score_sum_cost,
                                            sum: res.data.data.sum,
                                            title: '作品评分'
                                        },
                                        hidden: false,
                                        showNum: true,
                                        component: () => import('../views/mark/giveMark'),
                                    }
                                    return item;
                                }
                                if (item.name == 'score') {
                                    item['children'][2] = {
                                        path: 'contrast',
                                        name: 'contrast',
                                        meta: {title: '仲裁回看'},
                                        hidden: false,
                                        showNum: false,
                                        component: () => import('../views/score/contrast'),
                                    }
                                    return item;
                                }
                            }
                        })
                        commit('updateMenuList', routeList);
                        commit('updateScoreRoteList', scoreList);
                        commit('updateScoreSum', res.data.data.score_sum);
                        commit('updateScoreAllSum', res.data.data.sum);
                        commit('updateScoreSumCost', parseFloat(res.data.data.score_sum_cost));
                        router.addRoutes(routeList) // 动态添加可访问路由表
                        resolve(res.data)
                    })
                    .catch(err => {
                        resolve(err)
                    })
            } else if (store.state.teacherType == 3) {
                // 向后端请求路由数据-阅卷
                getYjInfo2({})
                    .then(res => {
                        if (res.data.code != 200) return resolve(res.data);
                        let scoreList = res.data.data.scores;
                        let pathList = scoreList.map((item, index) => {
                            let basePath = {
                                path: 'scoreRote/' + item.id,
                                name: 'score' + item.id,
                                meta: {
                                    title: item.score + '分',
                                    cost: item.cost,
                                    my_score_num: item.my_score_num,
                                    parent_id: item.parent_id,
                                    round_num: item.round_num,
                                    score_level: item.score_level,
                                },
                                hidden: false,
                                showNum: true,
                                component: () => import('../views/score/scoreRote'),
                            }
                            return basePath;
                        })
                        let routeList = basePath.filter(item => {
                            if (!item.hidden) {
                                if (item.name == 'mark') {
                                    item['children'][0] = {
                                        path: 'giveMark',
                                        name: 'giveMark',
                                        meta: {
                                            score_sum: res.data.data.score_sum,
                                            score_sum_cost: res.data.data.score_sum_cost,
                                            sum: res.data.data.sum,
                                            title: '作品评分'
                                        },
                                        hidden: false,
                                        showNum: true,
                                        component: () => import('../views/mark/giveMark'),
                                    }
                                    return item
                                }
                                if (item.name == 'score') {
                                    item.children = [item.children[0], item.children[1], item.children[2], ...pathList]
                                    return item
                                }
                            }
                        })
                        commit('updateMenuList', routeList);
                        commit('updateScoreRoteList', scoreList);
                        commit('updateScoreSum', res.data.data.score_sum);
                        commit('updateScoreAllSum', res.data.data.sum);
                        commit('updateScoreSumCost', parseFloat(res.data.data.score_sum_cost));
                        router.addRoutes(routeList) // 动态添加可访问路由表
                        resolve(res.data)
                    })
                    .catch(err => {
                        resolve(err)
                    })
            } else if (store.state.teacherType == 4) {
                // 向后端请求路由数据-临时修改
                getYjInfoT({})
                    .then(res => {
                        if (res.data.code != 200) return resolve(res.data);
                        let scoreList = res.data.data.scores;
                        let routeList = basePath.filter(item => {
                            if (!item.hidden) {
                                if (item.name == 'mark') {
                                    item['children'][0] = {
                                        path: 'giveMark',
                                        name: 'giveMark',
                                        meta: {
                                            score_sum: res.data.data.score_sum,
                                            score_sum_cost: res.data.data.score_sum_cost,
                                            sum: res.data.data.sum,
                                            title: '作品评分'
                                        },
                                        hidden: false,
                                        showNum: true,
                                        component: () => import('../views/mark/giveMark'),
                                    }
                                    return item;
                                }
                            }
                        })
                        commit('updateMenuList', routeList);
                        commit('updateScoreRoteList', scoreList);
                        commit('updateScoreSum', res.data.data.score_sum);
                        commit('updateScoreAllSum', res.data.data.sum);
                        commit('updateScoreSumCost', parseFloat(res.data.data.score_sum_cost));
                        router.addRoutes(routeList) // 动态添加可访问路由表
                        resolve(res.data)
                    })
                    .catch(err => {
                        resolve(err)
                    })
            } else {
                resolve({})
            }
        })
    },
}
