import {request} from "./network";
import store from "../store";

// 获取当前登录的公共配置 轮次与科目等(正常老师)
export function getYjConf(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    return request({
        method: "post",
        url: "/yj/auth/getYjConf",
        data,
    });
}

// 获取当前登录的公共配置 轮次与科目等(仲裁老师)
export function getYjConfEnd(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    return request({
        method: "post",
        url: "/yj/auth/getYjConfEnd",
        data,
    });
}

// 获取当前登录的公共配置 轮次与科目等(二次评价老师)
export function getYjConf2(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    return request({
        method: "post",
        url: "/yj/auth/getYjConf2",
        data,
    });
}


// 获取当前登录的公共配置 轮次与科目等
export function getYjConfAll(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    return request({
        method: "post",
        url: "/yj/auth/getYjConfAll",
        data,
    });
}

// 获取评阅页面的基础信息与配置
export function getYjInfo(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getYjInfo",
        data,
    });
}

// 获取仲裁页面的基础信息与配置
export function getYjInfoEnd(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getYjInfoEnd",
        data,
    });
}

// 获取二次评阅页面的基础信息与配置
export function getYjInfo2(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getYjInfo2",
        data,
    });
}

// 获取该轮次所有分数
export function getYjScoreAll(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/auth/getYjScoreAll",
        data,
    });
}



// 获取评阅页面的基础信息与配置（临时修改）
export function getYjInfoT(data) {
    data['exam_uuid'] = store.state.examInfo.exam_uuid;
    data['subject_uuid'] = store.state.subject_uuid;
    data['assess_num'] = store.state.assess_num;
    return request({
        method: "post",
        url: "/yj/action/getYjInfoT",
        data,
    });
}
