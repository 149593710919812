import {
    request
} from "./network";

// 登录
export function login(data) {
    return request({
        method: "post",
        url: "/yj/auth/login",
        data,
    });
}

// 获取该轮次所有分数
export function getYjScoreAll(data) {
    return request({
        method: "post",
        url: "/yj/auth/getYjScoreAll",
        data,
    });
}

// 获取考试列表
export function getExam(data) {
    return request({
        method: "get",
        url: "/yj/auth/getExam",
        data,
    });
}

// 退出
export function loginOut(data) {
    return request({
        method: "post",
        url: "/yj/auth/loginOut",
        data,
    });

}
