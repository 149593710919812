import Vue from 'vue'
import Vuex from 'vuex'
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

let state = {
    // 上一页地址
    previousPage: "",
    // 数据
    data: [],
    examInfo: {},
    isAllCall: false,
    isCollapse: false,
    filePath: '',
    activePath: null,// 当前激活的路由
    fromPath: null,// 跳转前激活的路由
    isLogin: localStorage.getItem("isLogin") || "0", // 只有1为已登录
    loginOutDialog: false,
    teacherType: null,// 老师类型（1-阅卷老师 2-仲裁老师 3-第二轮次）
    menuList: [],// 路由列表
    scoreRoteList: [],// 分数等级列表
    conf_assess_num: [], // 评论等级列表
    conf_subject: [], // 科目列表
    assess_num: null,
    subject_uuid: null,
    assessObj: {},
    subjectObj: {},
    score_sum: null,// 未评阅-已评阅作品数量
    score_all_sum: null,// 未评阅-作品总数量
    score_sum_cost: null,// 未评阅-已评阅作品百分百
    otherScoreRoteList: [],// 其他分数等级列表
    levelScoreRoteList: [],// 分档分数等级列表
    msgDialog: false,
    showMsg: '',
};

export default new Vuex.Store({
    plugins: [createPersistedState()], //解决刷新state消失的问题
    state,
    mutations,
    actions,
    getters,
});
